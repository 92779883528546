import React from "react";
import firebase from "firebase";
import { TimePeriod } from "./TimePeriod";

const MobileUser = (props) => {
    const logout = async () => {
        firebase.auth().signOut();
    };
    let { user } = props;
    let { name, photoUrl } = user.myuser;

    return (
        <>
            <section className="d-flex container-fluid">
                <a href="/" className="align-self-center brand-name d-lg-none">
                    gwapp
                </a>
                <h6 className="d-none d-lg-block mt-4">
                    <TimePeriod name={user.myuser.name} />
                </h6>
                <a
                    className="ml-auto mt-2 rounded-circle inner-outer-shadow logout-icon"
                    onClick={logout}
                    href="*"
                >
                    <i className="fas fa-power-off"></i>
                </a>
            </section>
            < hr />
            <div className="container">
                {/* <em className="text-muted">loading stats...</em> */}
            </div>
            <section className="d-lg-none inner-shadow mt-5 px-2 py-4 sm-header mx-2">
                <div className="row">
                    <div className="col-2">
                        <img
                            className="rounded-circle ml-3"
                            width="50"
                            height="50"
                            alt="avatar"
                            src={photoUrl}
                        />
                    </div>
                    <div className="col-10">
                        <h4 className="ml-5 heading-3 font-weight-bold align-self-center">
                            <TimePeriod name={name} />!
                        </h4>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MobileUser;
