import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import { Formik, useFormikContext} from 'formik';
import {fetchVendorAccounts} from '../actions/dashboardActions';
import {useDispatch} from 'react-redux';
import firebase from 'firebase';

import LoadingItems from './subcomponent/LoadingItems';



const VendorListItem = props =>{
    let [mode, setMode] = useState('add');
    let auth = useSelector(({auth})=>auth)
    let {handleChange, vendor, index} = props;
    
    let {values, setFieldValue} = useFormikContext()

    const saveVendor = async ()=>{
        await firebase.database().ref(`userVendors/${auth.uid}/${vendor.id}`).set(vendor);
        setMode("add")
    }

    const removeVendor = async ()=>{
        await firebase.database().ref(`userVendors/${auth.uid}/${vendor.id}`).remove();
        values.vendors.splice(props.index, 1);
        setFieldValue('vendors', values.vendors);
    }
    


    return (
    <div className="row mt-5 py-3 mx-0 mx-lg-2">
            <div className="col-lg-3 ">
                <label className="item-list-label">Account Name</label>
                <input value = {vendor.accountName} name = {`vendors.${props.index}.accountName`} onChange = {handleChange}    className={`form-control ${mode == 'edit' ? "inner-shadow-sm": "disabled"}`} />
            </div>
            <div className="col-lg-3">
                    <label className = "item-list-label">Bank Name</label>
                    <input value = {vendor.bankName} onChange = {handleChange} name = {`vendors.${props.index}.bankName`}    className={`form-control ${mode == 'edit' ? "inner-shadow-sm": "disabled"} m-0 mt-1`} />
            </div>
            <div className="col col-lg-3">
                <label className = "item-list-label">Account Number</label>
                <input value={vendor.accountNumber} onChange={handleChange} name={`vendors.${props.index}.accountNumber`} className={`form-control ${mode == 'edit' ? "inner-shadow-sm": "disabled"} m-0 mt-1`} />
            </div>
            <div className = "col-lg-3 d-lg-flex py-3">

                {
                    mode == "edit" ?
                    <button type = "button" onClick = {()=> saveVendor()} className="btn gw-info-color btn-md">
                            <i className="fas fa-save"></i>
                    </button> :
                    <button type = "button" onClick = {()=>setMode('edit')} className="btn gw-success-color btn-md">
                    <i className="fas fa-pen"></i>
                    </button> 

                }
                        
                        <button type = "button" onClick = {()=> removeVendor()} className="btn gw-danger-color btn-md">
                            <i className="fas fa-trash"></i>
                        </button> 
            </div>
    </div>);
}



const VendorList  = props =>{

    let vendorList = useSelector(({vendorList})=> vendorList);
    let auth = useSelector(({auth})=>auth)
    let dispatch = useDispatch();
    useEffect(()=>{
        fetchVendorAccounts(auth.uid, dispatch);
    }, []);

    const newVendor = ()=>{
        return {
            bankName: '',
            accountNumber: '',
            accountName: 'New',
            id: firebase.database().ref(`userVendors/${auth.uid}`).push().key
        }
    }
    if(vendorList && vendorList.length > 0){
        return(
            <Formik 

            initialValues = {
                {
                    vendors: vendorList
                }
            }
            >
                

                {
                    ({values, handleChange, setFieldValue})=>(
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                            <h4 className="heading-4 my-4">Vendor List</h4>
                                <button onClick={() => {values.vendors.push(newVendor());setFieldValue('vendors', values.vendors) }} className="btn btn-gw-circle outer-shadow">
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>
                            <div className = "activity">

                            
                            {
                                values.vendors.map((vend, i)=>(
                                    <>
                                        
                                    <VendorListItem vendor= {vend} index = {i} handleChange = {handleChange} />
                                    </>
                                ))
                            }
                            </div>
                        </>
                        
                    )
                }
            </Formik>

        )
    }



    return (
        <LoadingItems/>
    )
}


export default VendorList;