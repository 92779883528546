import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import {FETCH_USER} from '../actions/types'
import { signIn } from '../actions/authActions';
import firebase from 'firebase';
import '../css/landing.css';
const tempuser = null;
const Login = props =>{
    const dispatch = useDispatch();
    var [loading, setLoading] = useState(false);
    var [adminLoading, setAdminLoading] = useState(false);

    const adminSignIn = () => {
        try {
            setAdminLoading(true);
            window.location.href ="https://gwappadmin.procurementmonitor.org/";
        } catch (e) {
            console.log(e)
        } finally {
            setAdminLoading(false);
        }
    }
    
    const googleSignIn = async ()=>{
        setLoading(true)
        let result = await signIn();
        if(result.err){
            setLoading(false);
        }
        if(result.user){
            let userId = tempuser || result.user.uid;
            let myuser = await firebase.database().ref(`users/${userId}/profile`).get();
            let user = result.user;
            user.myuser = myuser;
            dispatch({type: FETCH_USER, payload: user});
        }
    }
    return (
        <div className="container h-100 d-flex justify-content-center align-items-center">
            <div className="row">
                <div className="col-lg-6 header">
                    <h1 className="heading-1">For collecting Money</h1>
                    <p className="tagline ml-">Gwapp is an initiative of the PPDC Finance and Tech teams, to facilitate, organize and manage financial requisitions within the organisation</p>
                    {loading ? <button className="btn btn-gw-primary disabled"><i className="fas fa-spinner spinning"></i></button> :<button className="btn btn-gw-primary" onClick = {googleSignIn}>Sign in <i className="fab fa-google"></i></button>}
                    {adminLoading ? <button className="btn btn-gw-secondary"><i className="fas fa-spinner spinning"></i></button> : <button className="btn btn-gw-secondary" onClick={adminSignIn}>Admin Signin <i className="fab fa-google"></i></button>}
                </div>
                <div className="col-lg-6 d-none d-lg-block right-col-landing">
                    <div className="inner-shadow diamond diamond-1"></div>
                    <div className="inner-shadow diamond diamond-2"></div>
                    <div className="inner-shadow diamond diamond-3"></div>
                </div>
            </div>
        </div>
    )
}


export default Login;