import {useSelector} from 'react-redux'


const ProjectCodes = (props)=>{
    let codes = useSelector(({projectCodes})=>projectCodes);
    console.log(codes);
    return(
        <>
        <option value = "">Budget Line Item</option>
        {
            codes && codes.map((cd, i)=>(
                <option key = {cd.id} value = {`${cd.code} - ${cd.description}`} >{cd.code}- {cd.description}</option>
            ))
        }
        </>
    )
}

export default ProjectCodes;