import { useDispatch, useSelector } from "react-redux";
import Stats from "../components/Stats";
import { useEffect, useState } from "react";
import {
    Route,
    Switch,
    useRouteMatch,
    Link,
    useHistory,
} from "react-router-dom";
import RequisitionList from "../components/RequisitionList";
import RequisitionForm from "../components/RequisitionForm";
import EditRequisitionForm from "../components/EditRequisitionForm";
import Conversation from "../components/Conversation";
import MobileUser from "../components/MobileUser";
import {
    recentRequisitions,
    fetchVendorAccounts,
} from "../actions/dashboardActions";
import { Alert } from "../components/Alert";
import { TimePeriod } from "../components/TimePeriod";
import InfoModal from "../components/InfoModal";

const HomeRoute = (props) => {
    let user = useSelector(({ auth }) => auth);
    let recentReqs = useSelector(({ recentReqs }) => recentReqs);
    let checkrecentReqs = recentReqs ? true : false;
    let dispatch = useDispatch();
    let { path, url } = useRouteMatch();
    let history = useHistory();
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        recentRequisitions(user.uid, dispatch);
    }, [checkrecentReqs]);

    useEffect(() => {
        fetchVendorAccounts(user.uid, dispatch);
    }, []);

    const user_signature = user.myuser.signatureUrl;

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <MobileUser user={user} />

                    {/* <Stats user={user} /> */}
                    <section className="my-5  recent-act p-3 h-75 px-2 px-lg-4">
                        <h6>You can no longer create requisitions. Please use the new Gwapp</h6>
                        <p><em>If you don't see your latest requisitions in the new Gwapp, keep calm and check again! It's being migrated.</em></p>
                        {/* {user_signature === null ||
                        user_signature === undefined ||
                        user_signature === "" ? (
                            <button
                                className="btn float-right outer-shadow"
                                onClick={Alert(
                                    "error",
                                    "User signature is required"
                                )}
                            >
                                create
                            </button>
                        ) : (
                            <Link to={`${url}/create`}>
                                <button className="btn float-right outer-shadow">
                                    create
                                </button>
                            </Link>
                        )} */}

                        <h4 className="mt-4 heading-5 ml-lg-2">
                            Your recent activities..
                        </h4>
                        <hr />
                        <RequisitionList user={user} recentReqs={recentReqs} />
                    </section>
                </Route>
                <Route path={`${path}/create`}>
                    <RequisitionForm />
                </Route>
                <Route path={`${path}/edit/:id`}>
                    <EditRequisitionForm />
                </Route>
                <Route path={`${path}/conversation/:id`}>
                    <Conversation />
                </Route>
            </Switch>
            {!localStorage.getItem("hideModal") && (
                <InfoModal
                    showModal={showModal}
                    handleClose={() => setShowModal(false)}
                />
            )}
        </>
    );
};

export default HomeRoute;
