import * as accounting from "accounting";
import * as dayjs from "dayjs";
import firebase from "firebase";
import users from "./users.json";
import BlankSignature from "../assets/white-blank.png";
import { BLANK_SIG } from "./constants";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("gwapp-react-client");

var userList = require("./userlist.json");

// eslint-disable-next-line no-extend-native
String.prototype.trunc = function (n, useWordBoundary) {
    if (this.length <= n) {
        return this;
    }
    var subString = this.substring(0, n - 1);
    return (
        (useWordBoundary
            ? subString.substring(0, subString.lastIndexOf(" "))
            : subString) + "..."
    );
};

export const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + " ..." : str;
};

export const ppdcLogoUrl =
    "https://firebasestorage.googleapis.com/v0/b/auth-f9812.appspot.com/o/systemimages%2Fppdc.jpg?alt=media&token=94b6c06a-98e2-4683-a40c-d98a6f7b8b59";

export const currencyCodes = {
    NGN: "₦",
    USD: "$",
    GBP: "£",
    EUR: "€",
};
export const currencyWord = {
    NGN: "naira",
    USD: "dollars",
    GBP: "pounds",
    EUR: "euros",
};
export const currencySubWord = {
    NGN: "kobo",
    USD: "cents",
    GBP: "pence",
    EUR: "cents",
};

export const createUserList = (userList, propsList) => {
    let newList = userList.map((user) => {
        return { ...user, selected: propsList.includes(user.email) };
    });
    return newList;
};

// numToWords :: (Number a, String a) => a -> String
export const numToWords = (s) => {
    var th = ["", "thousand", "million", "billion", "trillion"];
    var dg = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
    ];
    var tn = [
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
    ];
    var tw = [
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
    ];

    s = (s || "").toString();
    s = s.replace(/[\, ]/g, "");
    if (s != parseFloat(s)) return "not a number";
    var x = s.indexOf(".");
    if (x == -1) x = s.length;
    if (x > 15) return "too big";
    var n = s.split("");
    var str = "";
    var sk = 0;
    for (var i = 0; i < x; i++) {
        if ((x - i) % 3 == 2) {
            if (n[i] == "1") {
                str += tn[Number(n[i + 1])] + " ";
                i++;
                sk = 1;
            } else if (n[i] != 0) {
                str += tw[n[i] - 2] + " ";
                sk = 1;
            }
        } else if (n[i] != 0) {
            str += dg[n[i]] + " ";
            if ((x - i) % 3 == 0) str += "hundred ";
            sk = 1;
        }
        if ((x - i) % 3 == 1) {
            if (sk) str += th[(x - i - 1) / 3] + " ";
            sk = 0;
        }
    }
    if (x != s.length) {
        var y = s.length;
        str += "point ";
        for (var i = x + 1; i < y; i++) str += dg[n[i]] + " ";
    }
    return str.replace(/\s+/g, " ");
};

export const converNumtoWord = (number, currency) => {
    let bits = "" + parseFloat(number);
    bits = bits.split(".");
    bits[1] = bits[1] ? parseInt(bits[1]) : null;
    let firspart = "" + numToWords(parseInt(bits[0])) + currencyWord[currency];
    let seconpart = bits[1]
        ? ", " + numToWords(parseInt(bits[1])) + currencySubWord[currency]
        : "";

    return firspart + seconpart + " only";
};

export const total = (items) => {
    let tots = 0;
    items.map((item) => {
        tots += item.amount == "" ? 0 : parseFloat(item.amount);
    });
    return tots.toFixed(2);
};

// export const cloudURL = "https://us-central1-auth-f9812.cloudfunctions.net/";
export const cloudURL =
    window.location.host === "localhost:3000"
        ? "http://localhost:5001/auth-f9812/us-central1/"
        : "https://us-central1-auth-f9812.cloudfunctions.net/";
export const ABS_PATH =
    window.location.host === "localhost:3000"
        ? "http://localhost:3000"
        : "https:gwapp.procurementmonitor.org/";

export const printDocument = async (req) => {
    const tempDrToyinSig =
        "https://firebasestorage.googleapis.com/v0/b/auth-f9812.appspot.com/o/signatures%2Fdrtoyin2.jpeg?alt=media&token=897d6a33-d9fc-4701-aad7-2e8ac0594ec5";
    const tempBunmiSig = BlankSignature;
    const tempFavourSig =
        "https://firebasestorage.googleapis.com/v0/b/auth-f9812.appspot.com/o/signatures%2F451F25F8-D432-4FAA-A58D-4A9920AF9967.jpeg?alt=media&token=3338924f-27fb-4ffb-bd82-38484fcdc5de";

    const no_sig = await tobase64(
        "https://res.cloudinary.com/sastech/image/upload/v1666735645/Rectangle_7_lwxlre.png"
    );

    const myuser = JSON.parse(localStorage.getItem("gwapp_client_myuser_sig"));
    const myuser_sig = cryptr.decrypt(myuser);

    if (!req.approvedBy && !req.checkedBy) {
        return {};
    }
    let SignatureSection, SourceSection, ref;
    let userDate = dayjs(Math.abs(req.date)).format("DD/MM/YYYY");
    let budgetHolderDate = dayjs(Math.abs(req.hoderCheckDate)).format(
        "DD/MM/YYYY"
    );
    let checkDate = dayjs(Math.abs(req.checkedDate)).format("DD/MM/YYYY");
    let approveDate = dayjs(Math.abs(req.approvedDate)).format("DD/MM/YYYY");
    let reqDate = dayjs(Math.abs(req.date));
    let date = reqDate.format("DD MMM YYYY");
    let title = req.type === "Fund Req" ? "FUND REQUISITON" : "PETTY CASH";
    console.log(req);

    // let userSignature = await tobase64(myuser_sig);

    let userSignature = req.user?.signatureUrl
        ? await tobase64(req.user.signatureUrl)
        : await tobase64(myuser_sig);
    let logoUrl = await tobase64(ppdcLogoUrl);
    let approveSignature = await tobase64(req.approvedBy.signatureUrl);
    if (req.type === "Fund Req") {
        // req.holderCheck = {
        //     "email": "adeyemi@procurementmonitor.org",
        //     "name": "Olubunmi Adeyemi"
        // }
        ref =
            reqDate.format("DD-MM-YY") +
            req.projectId.substr(0, 10) +
            "-" +
            req.projectName.substr(0, 3).toUpperCase();
        let budgetHolderSig =
            req.holderCheck.email === "oluwatoyin_cacm@procurementmonitor.org"
                ? await tobase64(tempDrToyinSig)
                : // : req.holderCheck.email === "adeyemi@procurementmonitor.org"
                // ? await tobase64(tempBunmiSig)
                req.holderCheck.email === "favour@procurementmonitor.org"
                ? await tobase64(tempFavourSig)
                : req.holderCheck.signatureUrl
                ? await tobase64(req.holderCheck.signatureUrl)
                : no_sig;
        // let budgetHolderSig = req.holderCheck.signatureUrl
        //     ? await tobase64(req.holderCheck.signatureUrl)
        //     : ""

        let checkedSignature = req.checkedBy ? await tobase64(req.checkedBy.signatureUrl) : no_sig;
        
        SignatureSection = [
            [
                { text: "Raised By", style: "labelStyle" },
                { text: req.user.name, style: "fieldStyle" },
                { text: userDate, style: "fieldStyle" },
                { image: userSignature, width: 30, height: 30 },
            ],
            [
                { text: "Budget Holder", style: "labelStyle" },
                {
                    text: req.holderCheck ? req.holderCheck.name : "",
                    style: "fieldStyle",
                },
                {
                    text: budgetHolderDate ? budgetHolderDate : "",
                    style: "fieldStyle",
                },
                { image: budgetHolderSig, width: 30, height: 30 },
            ],
            [
                { text: "Checked By", style: "labelStyle" },
                {
                    text: req.checkedBy ? req.checkedBy.name : "",
                    style: "fieldStyle",
                },
                { text: checkDate ? checkDate : "", style: "fieldStyle" },
                { image: checkedSignature, width: 30, height: 30 },
            ],
            [
                { text: "Approved By", style: "labelStyle" },
                { text: req.approvedBy.name, style: "fieldStyle" },
                { text: approveDate, style: "fieldStyle" },
                { image: approveSignature, width: 30, height: 30 },
            ],
        ];

        SourceSection = [
            [
                {
                    text: "Project Name",
                    style: "labelStyle",
                },
                {
                    text: req.projectName,
                    style: "fieldStyle",
                },
            ],
            [
                {
                    text: "Source Bank Name",
                    style: "labelStyle",
                },
                {
                    text: req.sourceBankName,
                    style: "fieldStyle",
                },
            ],
            [
                {
                    text: "Source Account Number",
                    style: "labelStyle",
                },
                {
                    text: req.sourceAccountNumber,
                    style: "fieldStyle",
                },
            ],
        ];
    } else {
        SignatureSection = [
            [
                { text: "Raised By", style: "labelStyle" },
                { text: req.user.name, style: "fieldStyle" },
                { text: userDate, style: "fieldStyle" },
                { image: userSignature, width: 30, height: 30 },
            ],
            [
                { text: "Approved By", style: "labelStyle" },
                { text: req.approvedBy.name, style: "fieldStyle" },
                { text: approveDate, style: "fieldStyle" },
                { image: approveSignature, width: 30, height: 30 },
            ],
        ];
        SourceSection = [];
        ref = "Petty Cash";
    }

    let itemsDoc = [
        [
            { text: "Item Description", style: "labelStyle" },
            { text: "Budget Line", style: "labelStyle" },
            { text: "Amount", style: "labelStyle" },
        ],
    ];
    req.items.forEach((item) => {
        let row = [
            { text: item.title, style: "tableBodyText" },
            { text: item.code || "none", style: "tableBodyText" },
            {
                text: accounting.formatMoney(
                    item.amount,
                    currencyCodes[req.currency]
                ),
                style: "tableBodyText",
            },
        ];
        itemsDoc.push(row);
    });
    var dd = {
        header: {
            columns: [
                {
                    image: logoUrl,
                    width: 100,
                    height: 30,
                },
                {
                    text: ref,
                    margin: [130, 0, 0, 0],
                    style: "reference",
                },
            ],
            margin: [20, 10],
        },
        footer: {
            text: "Gwapp 2.0",
            style: "footerStyle",
        },
        content: [
            {
                text: title,
                style: "header",
            },
            {
                text: date,
                style: "dateStyle",
            },
            {
                text: "General Info",
                style: "subheader",
            },
            {
                text: "Name",
                style: "labelStyle",
            },
            {
                text: req.user.name,
                style: "fieldStyle",
            },
            {
                text: "Purpose of Payment",
                style: "labelStyle",
            },
            {
                text: req.title,
                style: "fieldStyle",
            },
            {
                text: "Project Charged To",
                style: "labelStyle",
            },
            {
                text: req.projectName,
                style: "fieldStyle",
            },
            {
                text: "Bank Details",
                style: "subheader",
            },
            {
                columns: [
                    [
                        {
                            text: "Name of Beneficiary",
                            style: "labelStyle",
                        },
                        {
                            text: req.accountName,
                            style: "fieldStyle",
                        },
                    ],
                    [
                        {
                            text: "Beneficiary Bank Name",
                            style: "labelStyle",
                        },
                        {
                            text: req.bankName,
                            style: "fieldStyle",
                        },
                    ],
                    [
                        {
                            text: "Beneficiary Account Number",
                            style: "labelStyle",
                        },
                        {
                            text: req.accountNumber,
                            style: "fieldStyle",
                        },
                    ],
                ],
            },
            {
                columns: SourceSection,
            },
            {
                text: "Items",
                style: "subheader",
            },
            {
                style: "tableExample",
                table: {
                    headerRows: 1,
                    widths: ["*", "auto", 50],
                    body: itemsDoc,
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                    },
                },
            },
            {
                columns: [
                    [
                        { text: "Total", style: "labelStyle" },
                        {
                            text: accounting.formatMoney(
                                req.total,
                                currencyCodes[req.currency]
                            ),
                            style: "fieldStyle",
                        },
                    ],
                    [
                        { text: "Amount In Words", style: "labelStyle" },
                        { text: req.amountInWords, style: "fieldStyle" },
                    ],
                ],
            },
            {
                columns: SignatureSection,
            },
        ],

        styles: {
            reference: {
                color: "#08713E",
            },

            header: {
                fontSize: 24,
                alignment: "center",
                margin: [0, 10],
            },
            subheader: {
                fontSize: 16,
                bold: true,
                color: "#CEAFE7",
                margin: [0, 15],
            },
            labelStyle: {
                bold: true,
                fontSize: 10,
                margin: [0, 10, 0, 3],
            },
            fieldStyle: {
                fontSize: 12,
                margin: [0, 3, 0, 5],
                color: "#B7ADAD",
            },
            tableExample: {
                margin: [0, 10],
            },
            tableBodyText: {
                fontSize: 10,
                italics: true,
            },
            dateStyle: {
                alignment: "center",
                fontSize: "10",
                bold: true,
                color: "#CEAFE7",
            },
            footerStyle: {
                alignment: "center",
                fontSize: 8,
                margin: [0, 0],
                italics: true,
                color: "#CEAFE7",
            },
        },
    };

    return dd;
};

const tobase64 = (url) => {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement("canvas");
        let img = document.createElement("img");
        var ctx = canvas.getContext("2d");
        img.setAttribute("src", url);
        img.crossOrigin = "anonymous";
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            let dataUrl = canvas.toDataURL("image/png");
            resolve(dataUrl);
        };
        img.onerror = function (e) {
            console.log("Error loading image:", e.message);

            img.setAttribute("src", BLANK_SIG);

            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                let dataUrl = canvas.toDataURL("image/png");
                resolve(dataUrl);
            };

            img.onerror = function (fallbackError) {
                console.log("Fallback image load error:", fallbackError.message);
                reject(fallbackError.message);
            };
        };
    });
};

export const putUserList = async () => {
    await firebase.database().ref("userList").set(userList);
    console.log("done writing the user list");
};
