import firebase from 'firebase';
import {FETCH_STATS, RECENT_REQS, PROJECT_CODES, VENDOR_LIST, USER_LIST, PROJECT_LIST} from '../actions/types';
import * as dayjs from 'dayjs';
const tempuser = null;


export const fetchStats = async(uid, dispatch)=>{
    let userId = tempuser || uid;
    let start = 0 - new Date(dayjs().date(1).format('YYYY-MM-DD')).getTime();
    let end = 0 - new Date().getTime();
    firebase.database().ref(`userRequisitions/${userId}`).orderByChild('time').endAt(start).on('value', (snapshot)=>{
        let newdata = {};
        snapshot.forEach((snap) => {
            let data = snap.val();
            if (newdata[data.status]) {
                newdata[data.status] += 1;
            }
            else newdata[data.status] = 1;
        });
        console.log(newdata);
        dispatch({type: FETCH_STATS, payload: newdata})

    })
   
}

export const fetchProjects = (dispatch) =>{
    let ref = firebase.database().ref(`projects`).once('value', (snapshot)=>{
        let projects = [];
        snapshot.forEach((snap)=>{
            projects.push(snap.val().projectName);
        })
        
        dispatch({type: PROJECT_LIST, payload: projects});
    })
}


export const recentRequisitions = async(uid, dispatch)=>{
    let userId = tempuser || uid
    
    let ref = firebase.database().ref(`userRequisitions/${userId}`).orderByChild('time').limitToFirst(100).on('value',(snapshot)=>{
    // let ref = firebase.database().ref(`userRequisitions/${userId}`).orderByChild('time').limitToFirst(370).on('value',(snapshot)=>{
        let recents = []
        snapshot.forEach((snap)=>{
            let rec = snap.val();
            rec.id = snap.key;
            recents.push(rec);
        })
        console.log(":::", recents);
        dispatch({type: RECENT_REQS, payload: recents})

    });
}

export const fetchProjectCodes = async(dispatch) =>{
    
    let ref = firebase.database().ref('budgetCodes').once('value',(snapshot)=>{
        let codes = [];
        snapshot.forEach((snap)=>{
            codes.push({id: snap.key, ...snap.val()});
        })
      

        dispatch({type: PROJECT_CODES, payload: codes});

    });
}

export const fetchVendorAccounts = async(uid, dispatch) =>{
    
    firebase.database().ref(`userVendors/${uid}`).on('value', (snapshot)=>{
        let vendors = [];
        snapshot.forEach((snap)=>{
            let vend = snap.val();
            vend.id = snap.key;
            vendors.push(vend);
        });

        dispatch({type: VENDOR_LIST, payload: vendors})
    })
}

export const addVendor = async(uid, accountName, accountNumber, bankName)=>{
    return firebase.database().ref(`userVendors/${uid}/${accountNumber}`).set({accountNumber, accountName, bankName});
}


export const reactUserList = async ()=>{

    firebase.database().ref('users').once('value', async (snapshot)=>{
        let userList = {};
        snapshot.forEach((snap)=>{
            let user = snap.val();
            if(user.profile && user.profile.photoUrl){
                let {name, email, photoUrl} = user.profile
                userList[snap.key] = {name, email, photoUrl}
            }
        });

        await firebase.database().ref('userList').set(userList);

    })

}

export const fetchUserList = (dispatch) =>{
    firebase.database().ref('userList').once('value', (snapshot)=>{
        let userList = [];
        snapshot.forEach((snap)=>{
            userList.push(snap.val());
            
        })
        console.log('userlist ',userList)
        dispatch({type: USER_LIST, payload: userList})
    })
}
