import { useState } from "react";
import { currencyCodes, printDocument, truncate } from "../../config/helpers";
import * as dayjs from "dayjs";
import * as accounting from "accounting";
import { Link } from "react-router-dom";
import { deleteRequisition } from "../../actions/requisitionActions";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ReactTooltip from "react-tooltip";
import { reqForDownload } from "../../tempReq";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const RequisitionItem = (props) => {
    let {
        date,
        approvedBy,
        holderCheck,
        checkedBy,
        currency,
        reviewedBy,
        status,
        title,
        total,
        id,
        userId,
        conversations,
    } = props.reqItem;

    let notcount = conversations
        ? conversations[userId]
            ? parseInt(conversations.count) - parseInt(conversations[userId])
            : conversations.count
        : "";
    let [deleting, setDeleting] = useState(false);
    let [prompt, setPrompt] = useState(false);
    let [isPrinting, setIsPrinting] = useState(false);
    let statusLabel = {
        approved: "Approved By",
        holderCheck: "Budget Holder Checked",
        holderChecked: "Budget Holder Checked",
        pending: "status",
        checked: "Finance Checked",
        reviewed: "Reviewer Checked",
        sentBack: "status",
        uploaded: "status",
        paymentMade: "status",
    };
    let statusRender = {
        approved: approvedBy ? approvedBy.name : "",
        holderCheck: holderCheck ? holderCheck.name : "",
        holderChecked: holderCheck ? holderCheck.name : "",
        pending: status,
        checked: checkedBy ? checkedBy.name : "",
        reviewed: reviewedBy ? reviewedBy.name : "",
        sentBack: status,
        uploaded: status,
        paymentMade: "Payment Made",
    };
    let reqDate = new dayjs(Math.abs(date)).format("D MMM, YY");
    let curr = currencyCodes[currency];
    let amount = accounting.formatMoney(total, curr, 2);
    const deleteReq = async () => {
        setDeleting(true);
        await deleteRequisition(id, userId);
    };

    // for temporary downloading user requisitions
    const tempReq = reqForDownload;

    const print = async () => {
        try {
            setIsPrinting(true);
            let doc = await printDocument(props.reqItem);
            // let doc = await printDocument(tempReq);
            pdfMake.createPdf(doc).open();
            setIsPrinting(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="row mt-5 py-3 mx-0 mx-lg-2">
            <div className="col-lg-4 ">
                <p className="date m-0">{reqDate}</p>
                <p data-tip={title} className="desc m-0 mt-1">
                    {/* {title.trunc(80, true)} */}
                    {title && truncate(title, 80)}
                </p>
            </div>
            <div className="col-lg-5  det-container">
                <div className="col">
                    <span>Amount</span>
                    <h5 className="check-name mt-1">{amount}</h5>
                </div>
                <div className="col">
                    <span>{statusLabel[status]}</span>
                    <p className="check-name">{statusRender[status]}</p>
                </div>
            </div>
            {prompt ? (
                <div className="col-lg-3 d-flex flex-column">
                    {status == "checked" || status == "reviewed" ? (
                        <>
                            <h6 className="my-2">
                                Are you sure you want to delete ?
                            </h6>
                            <div className="d-flex justify-content-between">
                                <button
                                    onClick={deleteReq}
                                    className={`action-btn btn btn-gw-circle outer-shadow ${
                                        deleting ? "disabled loading" : ""
                                    }`}
                                >
                                    <i className="fas fa-check success-icon"></i>
                                </button>
                                <button
                                    onClick={() => {
                                        setPrompt(false);
                                    }}
                                    className="action-btn btn btn-gw-circle outer-shadow btn-danger"
                                >
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                        </>
                    ) : null}
                </div>
            ) : (
                <div className="col-lg-3 d-flex align-items-center action-container">
                    {status == "approved" ||
                    status == "checked" ||
                    status == "reviewed" ||
                    status == "paymentMade" ||
                    status == "uploaded" ? (
                        <button className="action-btn btn btn-gw-circle outer-shadow">
                            <i className="fas fa-check success-icon"></i>
                        </button>
                    ) : (
                        <Link
                            data-tip="Edit requisition"
                            to={`home/edit/${props.index}`}
                            className="action-btn btn btn-gw-circle outer-shadow"
                        >
                            <i className="fas fa-pen"></i>
                        </Link>
                    )}

                    {status == "approved" ||
                    status == "paymentMade" ||
                    status == "uploaded" ? (
                        <button
                            data-tip="Print requisition"
                            onClick={() => {
                                print();
                            }}
                            className="action-btn btn btn-gw-circle outer-shadow"
                        >
                            {" "}
                            {isPrinting ? (
                                <i className="fas fa-spinner spinning"></i>
                            ) : (
                                <i className="fas fa-print print-icon"></i>
                            )}
                        </button>
                    ) : (
                        <button
                            data-tip="Delete Requisition"
                            className="action-btn btn btn-gw-circle outer-shadow"
                            onClick={() => {
                                setPrompt(true);
                            }}
                        >
                            <i className="fas fa-trash del-icon"></i>
                        </button>
                    )}

                    <Link
                        data-tip="conversations"
                        to={`home/conversation/${id}`}
                        className="action-btn btn btn-gw-circle outer-shadow"
                    >
                        <i className="fas fa-comment-dots"></i>
                        <span className="badge shadow-none">
                            {notcount ? notcount : ""}
                        </span>
                    </Link>
                </div>
            )}
            <ReactTooltip />
        </div>
    );
};

export default RequisitionItem;
