import {useSelector, useDispatch} from 'react-redux'
import {setTheme} from '../actions/authActions'

const ThemeSelector = props =>{
    let themes = ["default", "dark", "royal-dark", "dark-lemon"]
    let theme = useSelector(({theme})=> theme);
    let dispatch = useDispatch()
    const changeTheme = (theme) =>{
        console.log(theme)
        setTheme(theme, dispatch);
    }
    return (
        <div className = "d-flex">
            {
                themes.map((name, i) => (
                    <div key = {`theme-${i}`} className={`theme-container d-flex flex-column align-items-center ${theme===name && 'selected'} `}onClick = {()=> changeTheme(name)}>
                        <div className={`inner-shadow-sm theme-circle ${name}`}>
                        </div>
                        <p className="theme-name">{name}</p>
                    </div>
                ))
            }
            
        </div>
    )
}

export default  ThemeSelector;