import { useState, useEffect, useRef } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import RequisitionSearchList from "./RequisitionSearchList";
import RequisitionModal from "./RequisitionModal";
import { cloudURL } from "../config/helpers";
import axios from "axios";
import firebase from "firebase";
import * as dayjs from "dayjs";

var tempuser = null;
const RequisitionSearch = (props) => {
    var [state, setState] = useState({
        isLoading: false,
        start: "",
        previousStart: [],
        searchList: [],
    });
    var [project, setProject] = useState("");
    var [startDate, setStartDate] = useState("");
    var [modalOpen, setModalOpen] = useState({ open: false, req: false });
    const auth = useSelector(({ auth }) => auth);
    var projects = useSelector(({ projects }) => projects);
    var { path, url } = useRouteMatch();
    const [lastItemTime, setLastItemTime] = useState(null);
    const [firstItemTime, setFirstItemTime] = useState(null);

    const fetchD = async (time) => {
        let reqObj = { userId: tempuser || auth.uid, project };
        const lastTime = time || null;

        firebase
            .database()
            .ref(`userRequisitions/${auth.uid}`)
            .orderByChild("time")
            .limitToFirst(50)
            .startAt(lastTime)
            .on("value", (snapshot) => {
                let result = [];
                snapshot.forEach((snap) => {
                    let rec = snap.val();
                    rec.id = snap.key;
                    result.push(rec);
                });
                let newState = {
                    ...state,
                    searchList: result,
                };
                const lastReq = result.pop();
                const firstReq = result[0];

                setFirstItemTime(firstReq.time);
                setLastItemTime(lastReq.time);
                setState(newState);
            });

        // try {
        //     let requisitions = await axios.post(
        //         cloudURL + "userRequisitions",
        //         reqObj
        //     );
        //     console.log(requisitions);
        //     let newState = {
        //         ...state,
        //         searchList: requisitions.data,
        //         start: requisitions.data[requisitions.data.length - 1].time,
        //     };
        //     console.log(newState);
        //     setState(newState);
        // } catch (error) {
        //     console.log(error);
        // }
    };

    useEffect(() => {
        fetchD(null);
    }, []);

    const goNext = () => {
        fetchD(lastItemTime)
    };
    const goBack = () => {
        fetchD(firstItemTime)
    };

    // const previous = async () => {
    //     let prev = state.previousStart.pop();
    //     prev = prev || 0 - new Date().getTime();
    //     let stateBefore = { ...state, isLoading: true };
    //     setState(stateBefore);
    //     let reqObj = { project, start: prev, userId: tempuser || auth.uid };
    //     try {
    //         let requisitions = await axios.post(
    //             cloudURL + "userRequisitions",
    //             reqObj
    //         );
    //         let stateAfter = {
    //             ...state,
    //             searchList: requisitions.data,
    //             isLoading: false,
    //             start: requisitions.data[requisitions.data.length - 1].time,
    //             previousStart: state.previousStart,
    //         };
    //         setState(stateAfter);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    const view = (i) => {
        console.log("viewing");
        let req = state.searchList[i];
        console.log({ viewingReq: req });
        setModalOpen({ open: true, req });
    };
    // const next = async () => {
    //     let history = [...state.previousStart, state.searchList[0].time];
    //     console.log(history);
    //     let stateBefore = { ...state, previousStart: history, isLoading: true };
    //     setState(stateBefore);
    //     let reqObj = {
    //         project,
    //         start: state.start,
    //         userId: tempuser || auth.uid,
    //     };
    //     try {
    //         let requisitions = await axios.post(
    //             cloudURL + "userRequisitions",
    //             reqObj
    //         );
    //         let newState = {
    //             ...state,
    //             searchList: requisitions.data,
    //             isLoading: false,
    //             start: requisitions.data[requisitions.data.length - 1].time,
    //             previousStart: history,
    //         };
    //         console.log("newState", newState);
    //         setState(newState);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    const filter = async () => {
        let stateBefore = { ...state, isLoading: true };
        let newdate = 0 - new Date(startDate).getTime();
        setState(stateBefore);
        let reqObj = { project, start: newdate, userId: tempuser || auth.uid };
        try {
            let requisitions = await axios.post(
                cloudURL + "userRequisitions",
                reqObj
            );
            let newState = {
                ...state,
                searchList: requisitions.data,
                isLoading: false,
                start: requisitions.data[requisitions.data.length - 1].time,
                previousStart: [],
            };
            setState(newState);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Switch>
            <Route exact path={path}>
                <div className="">
                    <h3 className="h3">Search Requisition</h3>
                    <h6 className="mt-4 heading-6">Filter</h6>
                    <form className="mt-4">
                        <div className="form-row mb-4">
                            <div className="col pick-date">
                                <label>Start From</label>
                                <input
                                    placeholder="Start From"
                                    type="date"
                                    id="example"
                                    className="px-3 form-control datepicker outer-shadow"
                                    value={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col">
                                <label>Project</label>
                                <select
                                    className="browser-default custom-select outer-shadow"
                                    value={project}
                                    onChange={(e) => {
                                        setProject(e.target.value);
                                    }}
                                >
                                    {projects &&
                                        projects.map((proj) => (
                                            <option key={proj} value={proj}>
                                                {proj}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="btn btn-gw-primary btn-md"
                            onClick={() => filter()}
                        >
                            {" "}
                            <i className="fas fa-filter"></i>Filter
                        </button>
                    </form>

                    <section className="my-5  recent-act p-3 h-75 px-2 px-lg-4">
                        {
                            <RequisitionSearchList
                                view={view}
                                recentReqs={
                                    state.searchList.length === 0 ||
                                    state.isLoading
                                        ? null
                                        : state.searchList
                                }
                            />
                        }
                    </section>

                    <div className="d-flex justify-content-between">
                        <button
                            type="button"
                            className={`btn btn-gw-secondary outer-shadow-sm ${
                                state.previousStart.length == 0
                                    // ? "disabled"
                                    ? ""
                                    : ""
                            }`}
                            // onClick={() => previous()}
                            onClick={() => goBack()}
                        >
                            Previous
                        </button>
                        <button
                            type="button"
                            className={`btn btn-gw-primary outer-shadow-sm ${
                                // state.isLoading && "disabled"
                                state.isLoading && ""
                            }`}
                            // onClick={() => next()}
                            onClick={() => goNext()}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </Route>
            <Route path={`${url}/view/:id`}>
                {<RequisitionModal requisitions={state.searchList} />}
            </Route>
        </Switch>
    );
};

export default RequisitionSearch;
