

import LoadingReqs from './subcomponent/LoadingItems';
import {currencyCodes, printDocument, truncate} from '../config/helpers'
import * as dayjs from 'dayjs';
import * as accounting from 'accounting';
import {Link, useRouteMatch} from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {useState} from 'react';


const RequisitionSearchItem = (props)=>{
    let {date, approvedBy, holderCheck, checkedBy, currency, reviewedBy, status, title, total , id, userId, conversations} = props.reqItem;
    let view = props.view;
    let {url} = useRouteMatch();
    let [isPrinting, setIsPrinting] = useState(false);
    let [isDownloading, setIsDownloading] = useState(false);
   
    let statusLabel = {
        approved: 'Approved By',
        holderCheck: 'Budget Holder Checked',
        pending: 'status',
        checked: 'Finance Checked',
        reviewed: 'Reviewer Checked',
        sentBack: 'status',
        uploaded: 'status',
        paymentMade: 'status'
    }
    let statusRender = {
        approved: approvedBy ? approvedBy.name : '',
        holderCheck: holderCheck ? holderCheck.name: '',
        pending: status,
        checked: checkedBy ? checkedBy.name: '',
        reviewed: reviewedBy? reviewedBy.name: '',
        sentBack: status,
        uploaded: status,
        paymentMade: "Payment Made"
    }
    let reqDate = new dayjs(Math.abs(date)).format('D MMM, YY');
    let curr = currencyCodes[currency];
    let amount = accounting.formatMoney(total, curr, 2);

    const print =  async ()=>{
        setIsPrinting(true);
        let doc = await printDocument(props.reqItem);
        console.log(doc);
        pdfMake.createPdf(doc).open();
        setIsPrinting(false);
    }

    const download = async()=>{
        setIsDownloading(true);
        let doc = await printDocument(props.reqItem);
        console.log(doc);
        pdfMake.createPdf(doc).download();
        setIsDownloading(false);
    }
       
    return (
        <div className="row mt-5 py-3 mx-0 mx-lg-2">
            <div className="col-lg-4 ">
                <p className="date m-0">{reqDate}</p>
                {/* <p  className="desc m-0 mt-1"> {title.trunc(100,true)} </p> */}
                <p  className="desc m-0 mt-1"> {title && truncate(title, 100)} </p>
            </div>
            <div className="col-lg-5  det-container">
                <div className="col">
                    <span>Amount</span>
                    <h5 className="check-name mt-1">{amount}</h5>
                </div>
                <div className="col">
                    <span>{statusLabel[status]}</span>
                    <p className="check-name">{statusRender[status]}</p>
                </div>
            </div>
            <div className="col-lg-3 d-flex align-items-center action-container">
                {status == 'approved' || status == 'paymentMade' || status == 'uploaded' ? <button onClick = {()=>{download()}} className="action-btn btn btn-gw-circle outer-shadow">{isDownloading ? <i className="fas fa-spinner spinning"></i> :<i className="fas fa-download success-icon"></i>}</button>: ''}

                {status == 'approved' || status == 'paymentMade' || status == 'uploaded' ? <button data-tip="Print requisition" onClick={() => { print() }} className="action-btn btn btn-gw-circle outer-shadow"> {isPrinting ? <i className="fas fa-spinner spinning"></i> : <i className="fas fa-print print-icon"></i>}</button>: '' }
                <Link to={`${url}/view/${props.index}`} className="btn action-btn btn-gw-circle  gw-info-color  outer-shadow">
                    <i className="fas fa-eye"></i>
                </Link>
            </div>
            
        </div>
    )
}






const RequisitionSearchList = (props)=>{
    let {user, recentReqs, view } = props
    
    if(recentReqs){
        
        let reqs = recentReqs.map((req, i) => <RequisitionSearchItem view = {view} reqItem={req} key = {req.id} index = {i} />)
        return (
            <div className = "activity">
                {reqs}
            </div>
        )

    }
    else{
        return(
            <LoadingReqs />
        )
    }

    
    
}

export default RequisitionSearchList