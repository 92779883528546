import {createGlobalStyle} from 'styled-components'






export const darKLemon = createGlobalStyle`:root{
    --bg-light: #1B2D38;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: rgb(17, 17, 17);
    --accent-color: #aaca35;
    --text-light: rgba(255, 248, 248, 0.644);
    --text-dark-bold: rgba(255, 255, 255, 0.925);
    --text-dark: #ffffff;
    --text-lighter: #fcf8f8;
    --text-dimmed: #ffffff;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  rgba(255, 255, 255, 0.1);
}`

export const defaultLight = createGlobalStyle`
:root{
    --bg-light: #e5ebe7;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: white;
    --accent-color: #CEAFE7;
    --text-light: rgba(46, 34, 34, 0.25);
    --text-dark-bold: rgba(14, 13, 13, 0.925);
    --text-dark: #282d31;
    --text-lighter: #c4c4c4;
    --text-dimmed: #9D94A3;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  white; 
}
`

export const defaultDark = createGlobalStyle`
:root{
    --bg-light: #121413;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: rgb(17, 17, 17);
    --accent-color: #CEAFE7;
    --text-light: rgba(255, 248, 248, 0.644);
    --text-dark-bold: rgba(255, 255, 255, 0.925);
    --text-dark: #ffffff;
    --text-lighter: #fcf8f8;
    --text-dimmed: #ffffff;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  rgba(255, 255,255, .1);
}
`

export const royalDark = createGlobalStyle`
:root{
    --bg-light: #6d4463;
    --bg-info: #A3D9EA;
    --bg-success: #A3EAD4;
    --bd-light: rgb(17, 17, 17);
    --accent-color: #FFC563;
    --text-light: rgba(255, 248, 248, 0.644);
    --text-dark-bold: rgba(255, 255, 255, 0.925);
    --text-dark: #ffffff;
    --text-lighter: #fcf8f8;
    --text-dimmed: #ffffff;
    --shadow-color-dark: rgba(0, 0, 0, .25);
    --shadow-color-light:  rgba(255, 255,255, .1);
}
`