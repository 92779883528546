export const FETCH_USER = 'fetch_user';

export const FETCH_STATS = 'fetch_stats';

export const RECENT_REQS = 'recent_requistions';

export const PROJECT_CODES = 'project_codes';

export const VENDOR_LIST = 'vendor_list';

export const USER_LIST = 'user_list';
export const PROJECT_LIST = 'project_list';
export const THEME_SELECT = 'theme_select';