import React from "react";

export const TimePeriod = (props) => {
    const [timePeriod, setTimePeriod] = React.useState("");
    const { name } = props;

    const [currentTime, setCurrentTime] = React.useState(0);
    const fname = name ? name.split(' ')[0] : '';

    const getTime = () => {
        const time = new Date().getHours();
        setCurrentTime(time);
    }

    React.useEffect(() => {
        getTime();

        if (currentTime < 12) {
            setTimePeriod("Good morning");
        } else if (currentTime >= 12 && currentTime < 17) {
            setTimePeriod("Good afternoon");
        } else {
            setTimePeriod("Good evening");
        }
    }, [currentTime]);

    return (<span>{timePeriod}, {fname}</span>)
};
