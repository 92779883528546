import { useState } from "react";
import {currencyCodes, printDocument} from '../config/helpers'
import * as dayjs from 'dayjs';
import * as accounting from 'accounting';
import {useParams, Link} from 'react-router-dom';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;



const RequisitionModal = props =>{
    let  {id} = useParams();
    let req = props.requisitions[id]
    let {date, user, bankName, accountNumber, projectChargedTo, amountInWords, receipts, invoices, items, sourceBankName, sourceAccountNumber, accountName, approvedBy, holderCheck, checkedBy, currency, reviewedBy, type, status, title, total , userId, conversations} = req
    
    
    let [isPrinting, setIsPrinting] = useState(false);
    let statusLabel = {
        approved: 'Approved By',
        holderCheck: 'Budget Holder Checked',
        pending: 'status',
        checked: 'Finance Checked',
        reviewed: 'Reviewer Checked',
        sentBack: 'status',
        uploaded: 'status',
        paymentMade: 'status'
    }
    let statusRender = {
        approved: approvedBy ? approvedBy.name : '',
        holderCheck: holderCheck ? holderCheck.name: '',
        pending: status,
        checked: checkedBy ? checkedBy.name: '',
        reviewed: reviewedBy? reviewedBy.name: '',
        sentBack: status,
        uploaded: status,
        paymentMade: "Payment Made"
    }
    let reqDate = new dayjs(Math.abs(date)).format('D MMM, YY');
    let curr = currencyCodes[currency];
    let amount = accounting.formatMoney(total, curr, 2);
    

    const print =  async ()=>{
        setIsPrinting(true);
        let doc = await printDocument(req);
        console.log(doc);
        pdfMake.createPdf(doc).open();
        setIsPrinting(false);
    }
    
    return (
        <div className={`modal-container open`} >
                            <div className = "modal-main">
                                <div className = "gw-modal outer-shadow-sm">
                                    <div className = "modal-header d-flex justify-content-center">
                                        <h5 className = "h5">Requisition</h5>
                                    </div>
                                    <div className = "modal-body">
                                        <div className = "row px-2 px-lg-5">
                                            <div className="col-6 mb-3">
                                                <label className="m-0 mb-2" for="name"> Name</label>
                                                <input value = {user && user.name} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <label className="m-0 mb-2" for="name"> Date</label>
                                                <input value = {reqDate} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-12 mb-3">
                                                <label className="m-0 mb-2" for="name"> title</label>
                                                <input value = {title} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-6 col-lg-4 mb-3">
                                                <label className="m-0 mb-2" for="name">Bank Name</label>
                                                <input value ={bankName}  type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-6 col-lg-4 mb-3">
                                                <label className="m-0 mb-2" for="name">Account Name</label>
                                                <input value = {accountName} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-6 col-lg-4 mb-3">
                                                <label className="m-0 mb-2" for="name">Account Number</label>
                                                <input value = {accountNumber} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            {
                                                type == "Fund Req" && sourceBankName && sourceAccountNumber && 
                                                (
                                                    <>
                                                    <div className="col-6  mb-3">
                                                        <label className="m-0 mb-2" for="name">Source Bank</label>
                                                        <input value={sourceBankName} type="text" id="name" className="form-control inner-shadow-sm disabled" />
                                                    </div>
                                                    <div className="col-6 mb-3">
                                                        <label className="m-0 mb-2" for="name">Source Account Number</label>
                                                        <input value={sourceAccountNumber} type="text" id="name" className="form-control inner-shadow-sm disabled" />
                                                    </div>
                                                    </>
                                                )
                                            }
                                            
                                            <div className = "col-12 mb-3">
                                                <h6 className="mt-4 mb-3">Item List</h6>
                                                {
                                                    items && items.map((item)=>(<div className = "row outer-shadow-sm py-2 my-1">
                                                    <div className  = "col-4 d-flex align-items-center">
                                                        <span>{item.title}</span>
                                                    </div>
                                                    <div className  = "col-2 d-flex align-items-center">
                                                            <span>{accounting.formatNumber(item.amount)}</span>
                                                    </div>
                                                    <div className  = "col-6 d-flex align-items-center">
                                                        <span>{item.code || '...' }</span>
                                                    </div>
                                                </div>))
                                                }
                                            </div>
                                            <div className="col-2 col-lg-2 mb-3">
                                                <label className="m-0 mb-2" for="name">Total</label>
                                                <input value = {amount} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-10 col-lg-10 mb-3">
                                                <label className="m-0 mb-2" for="name">Amount in Words</label>
                                                <input value = {amountInWords} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                            <div className="col-6 mb-3">
                                                <label className="m-0 mb-2" for="name">Currency</label>
                                                <input value = {curr} type="text" id="name" className="form-control inner-shadow-sm disabled"/>
                                            </div>
                                                {
                                                    projectChargedTo && (
                                                        <div className="col-6 mb-3">
                                                            <label className="m-0 mb-2" for="name">Project Charged To</label>
                                                            <input value={projectChargedTo} type="text" id="name" className="form-control inner-shadow-sm disabled" />
                                                        </div>
                                                    )
                                                }
                                            
                                            <div className = "col-12 mb-3">
                                                <h6 className="mt-4 mb-3">Invoices</h6>
                                                <div className = "row  py-2 my-1">
                                                    {
                                                        invoices && invoices.map((invoice)=>(<div className  = "col-4 col-lg-3 d-flex align-items-center outer-shadow-sm mb-3 mr-2">
                                                        <span className = "text-small">{invoice.name}</span>
                                                        <a href = {invoice.url} target = "_blank"><i className = "fa fa-eye gw-accent-color"></i></a> 
                                                        </div>))
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className = "col-12 mb-3">
                                                <h6 className="mt-4 mb-3">Receipts</h6>
                                                <div className = "row py-2 my-1">
                                                {
                                                        receipts && receipts.map((receipt)=>(<div className  = "col-4 col-lg-3 d-flex align-items-center outer-shadow-sm mb-3 mr-2">
                                                        <span className = "text-small">{receipt.name}</span>
                                                        <a href = {receipt.url} target = "_blank"><i className = "fa fa-eye gw-accent-color"></i></a> 
                                                        </div>))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer d-flex justify-content-start px-5">
                                            {['approved', 'uploaded', 'paymentMade'].includes(status) && <button onClick = {()=>print()} type="button" className="btn btn-gw-primary outer-shadow-sm">{ isPrinting ? <i className="fas fa-spinner spinning"></i>: <span><i className="fas fa-print print-icon mr-2"></i> Print</span>}</button> }
                                            
                                    </div>
                                    <Link to = "/requisitions"  className = "modal-close-button" type = "button"><i className="fa fa-times"></i></Link>
                                </div>
                            </div>
                        </div>

    )
}

export default RequisitionModal;