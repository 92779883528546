import firebase from 'firebase';

var firebaseConfig = {
  apiKey: "AIzaSyBxRmy-eoUZz8agDM4t1BE5ysYygaZL4WQ",
  authDomain: "auth-f9812.firebaseapp.com",
  databaseURL: "https://auth-f9812.firebaseio.com",
  projectId: "auth-f9812",
  storageBucket: "auth-f9812.appspot.com",
  messagingSenderId: "10330153027",
  appId: "1:10330153027:web:bc6326b764d39b2c56f4f6"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

 