import Projectcodes from "./ProjectCodes";
import { useFormikContext } from "formik";

const ReqItem = (props) => {
  let {
    titleName,
    amountName,
    codeName,
    codeValue,
    amountValue,
    titleValue,
    handleChange,
    index,
    key,
  } = props;
  let { setFieldValue, values } = useFormikContext();
  const removeItem = () => {
    let items = [...values.items];
    items.splice(index, 1);
    setFieldValue("items", items);
  };
  return (
    <div className="item-list outer-shadow-sm row" key={key}>
      <div className="col-12 col-md-4 d-flex flex-column justify-content-center mb-4">
        <label className="item-list-label">Description</label>
        <input
          className="item-list-title mb-0"
          type="text"
          value={titleValue}
          name={titleName}
          onChange={handleChange}
        />
      </div>
      <div className="d-flex flex-column col-12 col-md-4 justify-content-center mb-4">
        <label className="item-list-label ml-sm-4">Amount</label>
        <input
          type="number"
          className="item-list-amount mb-0"
          value={amountValue}
          name={amountName}
          onChange={handleChange}
        />
      </div>
      <div className="d-flex flex-column col-12 col-md-3 justify-content-center mb-4">
        <label className="item-list-label ml-sm-4">Buget Line</label>
        <input
          placeholder="Search Budget"
          list="projects"
          className="item-list-amount"
          name={codeName}
          value={codeValue}
          onChange={handleChange}
        />
      </div>
      <div className="col-lg-1 d-flex justify-content-center">
        <div className="item-list-actions">
          <button type="button" className="btn" onClick={removeItem}>
            <i className="fas fa-trash del-icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReqItem;
