import { Switch, Route, Redirect } from "react-router-dom";

import HomeRoute from "./HomeRoute";
import RequisitionSearch from "../components/RequisitionSearch";
import SettingsRoute from "./SettingRoute";

const MainRoute = (props) => {
  return (
    <Switch>
      <Route path="/requisitions">
        <RequisitionSearch />
      </Route>
      <Route exact path="/settings">
        <SettingsRoute />
      </Route>
      <Route path="/home">
        <HomeRoute />
      </Route>
      <Route path="/">
        <Redirect to="/home" />
      </Route>
    </Switch>
  );
};

export default MainRoute;
