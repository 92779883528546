import React from "react";
import { NavLink } from "react-router-dom";
const Cryptr = require("cryptr");
const cryptr = new Cryptr("gwapp-react-client");

const Navbar = (props) => {
    let { user } = props;
    let myuser = user.myuser;
    console.log({ myuser });
    if (myuser.signatureUrl) {
        const encryptedSig = cryptr.encrypt(myuser.signatureUrl);
        localStorage.setItem(
            "gwapp_client_myuser_sig",
            JSON.stringify(encryptedSig)
        );
    } else {
        const encryptedSig = cryptr.encrypt("https://img.freepik.com/free-photo/abstract-luxury-plain-blur-grey-black-gradient-used-as-background-studio-wall-display-your-products_1258-63641.jpg?w=2000");
        localStorage.setItem(
            "gwapp_client_myuser_sig",
            JSON.stringify(encryptedSig)
        );
    }
    let { name, photoUrl } = myuser;
    return (
        <div className="col-lg-3 d-none d-lg-block">
            <section className="container pt-3 inner-shadow db-left-col">
                <a href="/" className="align-self-center brand-name ml-3">
                    gwapp
                </a>
                <hr />
                <div className="d-flex flex-column text-center pt-4">
                    <img
                        width="120"
                        height="120"
                        className="rounded-circle mx-auto"
                        alt="avatar"
                        src={photoUrl}
                    />
                    <h3 className="mx-auto mt-2">{name}</h3>
                </div>
                <div className="d-flex flex-column justify-content-between nav align-items-center mt-5">
                    <NavLink
                        to="/home"
                        className="outer-shadow"
                        activeClassName="nav-active"
                    >
                        <i className="fas fa-home mr-2"></i>Home
                    </NavLink>

                    <NavLink
                        to="/requisitions"
                        className="outer-shadow"
                        activeClassName="nav-active"
                    >
                        <i className="fas fa-list mr-2"></i>Requisitions
                    </NavLink>

                    <NavLink
                        to="/settings"
                        className="outer-shadow"
                        activeClassName="nav-active"
                    >
                        <i className="fas fa-cog mr-2"></i>Settings
                    </NavLink>
                </div>
            </section>
        </div>
    );
};

export default Navbar;
