import firebase from 'firebase';
import {FETCH_USER, THEME_SELECT} from './types';
const tempuser = null;

export const fetchUser = (dispatch)=>{
    
    firebase.auth().onAuthStateChanged( async user=>{
        
        if(user){
            let userId = tempuser || user.uid;
            console.log(user);
            try {
                let myuser = await firebase.database().ref(`users/${userId}/profile`).get();
                
                user.myuser = myuser.val();
                
                dispatch({type: FETCH_USER, payload: user})
            } catch (error) {
                
            }
           
        }
        else{
            dispatch({type: FETCH_USER, payload: null})
        }
    })
}


export const signIn = async (dispatch) =>{
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
        let result = await  firebase.auth().signInWithPopup(provider)
        
        return result;

    } catch (e) {
        console.log(e)
        return {err: e, errorMessage: e.errorMessage}
    }

}

export const fetchTheme = () =>{
    let userSelectedTheme = window.localStorage.getItem('gwapp-theme');
    console.log({userSelectedTheme})
    const hasSelectedTheme = typeof userSelectedTheme === 'boolean';
    return userSelectedTheme ? userSelectedTheme: 'default';
}

export const setTheme = (theme, dispatch)=>{
    window.localStorage.setItem('gwapp-theme', theme);
    dispatch({type: THEME_SELECT, payload: theme});
}