import { FETCH_USER, THEME_SELECT } from '../actions/types';
import {fetchTheme} from '../actions/authActions'

export default function(state = null, action){
    switch (action.type) {
        case FETCH_USER:
            return action.payload || false
        default:
            return state
    }
}

export const theme  = (state=null, action)=>{
    switch (action.type) {
        case THEME_SELECT:
            return action.payload || false
        default:
            return fetchTheme();
    }
}